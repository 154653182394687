<template>
  <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="已建账" name="已建账"></el-tab-pane>
    <el-tab-pane label="未建账" name="未建账"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" />
        <el-button class="filter-item1" type="primary" size="small" icon="Search" @click="getList">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 6px;width:600px" class="styleForm">
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <selectCjJzStatus v-model:allStatus="listQuery.allStatus"></selectCjJzStatus>
            </el-form-item>
            <el-form-item label="选择人员：" :label-width="formLabelWidth">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
            </el-form-item>
            <!-- <el-form-item label="包含员工：" :label-width="formLabelWidth">
              <el-radio v-model="listQuery.EmpStatus" :label="1">已补充员工</el-radio>
              <el-radio v-model="listQuery.EmpStatus" :label="0">全部</el-radio>
            </el-form-item> -->
            <el-form-item label="员工采集状态：" :label-width="formLabelWidth">
                <el-radio v-model="listQuery.empCj" :label="0">未采集</el-radio>
                <el-radio v-model="listQuery.empCj" :label="1">已采集</el-radio>
                <el-radio v-model="listQuery.empCj" :label="2">全部</el-radio>
            </el-form-item>
            <el-form-item label="员工补充状态：" :label-width="formLabelWidth">
                <el-radio v-model="listQuery.bcEmp" :label="0">未补充</el-radio>
                <el-radio v-model="listQuery.bcEmp" :label="1">已补充</el-radio>
                <el-radio v-model="listQuery.bcEmp" :label="2">全部</el-radio>
            </el-form-item>
              <el-form-item label="资产采集状态：" :label-width="formLabelWidth">
                <el-radio v-model="listQuery.assetCj" :label="0">未采集</el-radio>
                <el-radio v-model="listQuery.assetCj" :label="1">已采集</el-radio>
                <el-radio v-model="listQuery.assetCj" :label="2">全部</el-radio>
            </el-form-item>
            <el-form-item label="资产补充状态：" :label-width="formLabelWidth">
                <el-radio v-model="listQuery.bcAsset" :label="0">未补充</el-radio>
                <el-radio v-model="listQuery.bcAsset" :label="1">已补充</el-radio>
                <el-radio v-model="listQuery.bcAsset" :label="2">全部</el-radio>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()" v-if="this.activeName == '未建账'">批量采集</el-button>
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px" v-if="this.activeName == '已建账'">
          <el-button size="small" type="primary">
            批量补充<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <!-- <el-dropdown-item command="a">
                <span>存货</span>
              </el-dropdown-item> -->
              <el-dropdown-item command="b">
                <span>资产</span>
              </el-dropdown-item>
              <el-dropdown-item command="d">
                <span>员工</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-dropdown @command="handleCommand2" size="small" style="margin-left:10px" v-if="this.activeName == '已建账'">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a" @click="declaration()">
                <span>重新采集</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      
    </div>
    <div class="none-border">
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column align="center" type="index" label="编号" width="50" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="180" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span @click="$goEtax(scope.row.comId)" style="cursor:pointer;text-decoration: underline" type="text" v-if="scope.row.status == '2'">{{ scope.row.name }}</span>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="startAccountPeriod" label="初始账期" min-width="50" />
        <el-table-column align="center" prop="districtName" label="采集状态" min-width="150">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                  <el-tooltip v-if="scope.row.bussinessLog" class="scope.row" effect="dark"  placement="top-start">
                    <template #content>
                      <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"> </div>
                    </template>
                    <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                  </el-tooltip>
                  <span v-else>
                    <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                  </span>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="员工" min-width="100">
          <template #default="scope">
            <span v-if="scope.row.bcEmp == '1'"><i class="iconfont icon-duihao"></i>已补充</span>
            <span v-else><i class="iconfont icon-gantanhao"></i>未补充</span>
            <span v-if="scope.row.empCj == 0">
              <el-tooltip  effect="dark" placement="top-start" content="未检测到采集的员工" >
                <i class="iconfont icon-xiaoxizhongxin" style="color:red;margin-left: 5px;" ></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="资产" min-width="100">
          <template #default="scope">
            <span v-if="scope.row.bcAsset == '1'"><i class="iconfont icon-duihao"></i>已补充</span>
            <span v-else><i class="iconfont icon-gantanhao"></i>未补充</span>
            <span v-if="scope.row.assetCj == 0">
              <el-tooltip  effect="dark" placement="top-start" content="未检测到采集的资产" >
                <i class="iconfont icon-xiaoxizhongxin" style="color:red;margin-left: 5px;"></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="handleCheck(scope.row)" size="small" type="text" :disabled="scope.row.bussinessStatus != 2">查看</el-button>
              <el-button @click="declareDetail(scope.row)" size="small" type="text" :disabled="scope.row.taskStatus == 0">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
</el-tabs>

  <!-- 批量采集弹窗 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="批量采集"
    width="30%"
    destroy-on-close>
    <el-form :rules="rules" ref="ruleForm" :model="form">
      <el-form-item label="软件名称" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-select v-model="form.name" placeholder="请选择软件" size="small" style="margin-left:0px" filterable>
          <el-option label="金财互联" value="金财互联"></el-option>
          <el-option label="账无忧" value="账无忧"></el-option>
          <el-option label="云代账" value="云代账"></el-option>
          <el-option label="云代账V4" value="云代账V4"></el-option>

          <el-option label="亿企代账" value="亿企代账"></el-option>
          <el-option label="云帐房" value="云帐房"></el-option>
          <el-option label="浪潮云" value="浪潮云"></el-option>
          <el-option label="柠檬云" value="柠檬云"></el-option>
          <el-option label="柠檬云专业版" value="柠檬云专业版"></el-option>
          <el-option label="账无忧1.0" value="账无忧1.0"></el-option>

          <el-option label="柠檬云免费版" value="柠檬云免费版"></el-option>
          <el-option label="大账房" value="大账房"></el-option>

          <el-option label="精斗云" value="精斗云"></el-option>
          <el-option label="易代账" value="易代账"></el-option>
          <el-option label="诺诺云" value="诺诺云"></el-option>
          <el-option label="快合财税" value="快合财税"></el-option>
          <el-option label="木牛盒子" value="木牛盒子"></el-option>
          <el-option label="代账魔方" value="代账魔方"></el-option>
          <el-option label="唯易" value="唯易"></el-option>
          <el-option label="财务云" value="财务云"></el-option>
          <el-option label="好会计" value="好会计"></el-option>
          <el-option label="天蓬云账" value="天蓬云账"></el-option>

          <el-option label="金账师" value="金账师"></el-option>
          <el-option label="快账" value="快账"></el-option>
          <el-option label="芸豆会计" value="芸豆会计"></el-option>
          <el-option label="云算盘" value="云算盘"></el-option>
          <el-option label="呱呱云" value="呱呱云"></el-option>
          <el-option label="慧算账" value="慧算账"></el-option>
          <el-option label="慧代账" value="慧代账"></el-option>
          <el-option label="捷锐云" value="捷锐云"></el-option>
          <el-option label="365云财税" value="365云财税"></el-option>
          <el-option label="众马云" value="众马云"></el-option>
          <el-option label="智慧财税" value="智慧财税"></el-option>
          <el-option label="芸税通(个税)" value="芸税通(个税)"></el-option>






        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户名" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.userName" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="password" label="密码" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.password" size="small"></el-input>
      </el-form-item>

      <el-form-item v-if="form.name == '云代账' || form.name == '云代账V4'|| form.name == '代账魔方'||  form.name == '财务云'" prop="orgCode" label="公司代码" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.orgCode" size="small"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>


  <!--查看科目余额表 -->
  <el-dialog :close-on-click-modal="false"
    v-model="checkDialogVisible"
    title="查看"
    width="80%"
    destroy-on-close>
    <el-tabs type="border-card" @tab-click="handleDialogClick">
      <el-tab-pane label="科目余额表">
        <el-switch style="margin-bottom: 10px;" v-model="changeWb" active-text="外币科目余额表" inactive-text="普通科目余额表"></el-switch>
        <el-table stripe :height="contentStyleObj2" :data="showList" border  v-loading="loading">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column label="科目编码" width="160" align="left" fixed>
            <template #default="scope">
              <span class="bianma"  style="color: var(--themeColor,#17a2b8);">{{scope.row.subjectCode}}</span>
            </template>
          </el-table-column>

          <el-table-column label="科目名称" min-width="250" align="left" fixed>
            <template #default="scope">
              <span class="name_css">{{scope.row.subjectName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位" min-width="150" align="center">
            <template #default="scope">
              <span class="name_css">{{scope.row.unit}}</span>
            </template>
          </el-table-column>
          <el-table-column label="型号" min-width="150" align="center">
            <template #default="scope">
              <span class="name_css">{{scope.row.spec}}</span>
            </template>
          </el-table-column>
          <el-table-column label="期初余额" align="center">
            <el-table-column  align="center" prop="periodBeginIn" label="借方" width="160">
              <template #default="scope">
                <span>{{scope.row.periodBeginIn}}</span>
                
              </template>
            </el-table-column>
            <el-table-column  align="center" prop="periodBeginOut" label="贷方" width="160">
              <template #default="scope">
                <span>{{scope.row.periodBeginOut}}</span>
              </template>
            </el-table-column>

            <el-table-column v-if="changeWb" align="center" prop="periodBeginInWb" label="外币借方" width="160">
              <template #default="scope">
                <span>{{scope.row.periodBeginInWb}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="changeWb" align="center" prop="periodBeginOutWb" label="外币贷方" width="160">
              <template #default="scope">
                <span>{{scope.row.periodBeginOutWb}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="beginCount" label="数量" width="160">
              <template #default="scope">
                <span>{{scope.row.beginCount}}</span>
              </template>
            </el-table-column>

          </el-table-column>

          <el-table-column label="本期发生额" align="center">
            <el-table-column align="center" prop="periodInt" label="借方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodInt}}</span>
              </template>
            </el-table-column>  
            <el-table-column align="center" prop="periodOut" label="贷方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodOut}}</span>
              </template>
            </el-table-column>

            <el-table-column v-if="changeWb" align="center" prop="periodIntWb" label="外币借方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodIntWb}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="changeWb" align="center" prop="periodOutWb" label="外币贷方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodOutWb}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="inCount" label="数量借" width="120">
              <template #default="scope">
                <span>{{scope.row.inCount}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="outCount" label="数量贷" width="120">
              <template #default="scope">
                <span>{{scope.row.outCount}}</span>
              </template>
            </el-table-column>

          </el-table-column>

          <el-table-column label="本年累计发生额" align="center">
            <el-table-column align="center" prop="yearInt" label="借方" width="160">
              <template #default="scope">
                <span>{{scope.row.yearInt}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="yearOut" label="贷方" width="160">
              <template #default="scope">
                <span>{{scope.row.yearOut}}</span>
              </template>
            </el-table-column>

            <el-table-column v-if="changeWb" align="center" prop="yearIntWb" label="外币借方" width="160">
              <template #default="scope">
                <span>{{scope.row.yearIntWb}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="changeWb" align="center" prop="yearOutWb" label="外币贷方" width="160">
              <template #default="scope">
                <span>{{scope.row.yearOutWb}}</span>
              </template>
            </el-table-column>

          </el-table-column>

          <el-table-column label="期末余额" align="center">
            <el-table-column align="center" prop="periodEndIn" label="借方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodEndIn}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="periodEndOut" label="贷方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodEndOut}}</span>
              </template>
            </el-table-column>

            <el-table-column v-if="changeWb" align="center" prop="periodEndInWb" label="外币借方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodEndInWb}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="changeWb" align="center" prop="periodEndOutWb" label="外币贷方" width="120">
              <template #default="scope">
                <span>{{scope.row.periodEndOutWb}}</span>
              </template>
            </el-table-column>


            <el-table-column align="center" prop="endCount" label="数量" width="120">
              <template #default="scope">
                <span>{{scope.row.endCount}}</span>
              </template>
            </el-table-column>

          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="员工"> 
        <el-table stripe :height="contentStyleObj2" :data="showList2" border fit highlight-current-row v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="index" width="60" label="序号"> </el-table-column>
        <el-table-column label="姓名"  min-width="80">
            <template #default="{row}">
                <span>{{row.name}}</span>
            </template>
        </el-table-column>
        <el-table-column label="证件类型" min-width="100">
          <template #default="{row}">
                <span>{{row.id_type}}</span>
          </template>
        </el-table-column>
        <el-table-column label="证件号码" min-width="180">
          <template #default="{row}">
                <span>{{row.id_no}}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" min-width="60">
          <template #default="{row}">
            <span v-if="row.sex == 1">男</span>
                <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column label="出生日期" min-width="100">
          <template #default="{row}">
                <span>{{row.birthday}}</span>
          </template>
        </el-table-column>
       <!--  <el-table-column label="是否雇员" min-width="80">
          <template #default="{row}">
            <span v-if="row.isEmployee == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column> -->
        <el-table-column label="任职受雇日期" min-width="120">
          <template #default="{row}">
                <span>{{row.join_date}}</span>
          </template>
        </el-table-column>
        <el-table-column label="离职日期" min-width="100">
          <template #default="{row}">
                <span>{{row.departure_date}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否股东" min-width="80">
          <template #default="{row}">
            <span v-if="row.stockholder == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="国籍" min-width="80">
          <template #default="{row}">
                <span>{{row.country}}</span>
          </template>
        </el-table-column>
      <!--   <el-table-column label="部门" min-width="100">
          <template #default="{row}">
                <span>{{row.department}}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="是否扣除减除费用" min-width="100">
          <template #default="{row}">
                <span v-if="row.sfkcjcfy == 1">是</span>
                <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="人员状态" min-width="90">
          <template #default="{row}">
             <span>{{row.status}}</span>
          </template>   
        </el-table-column>
      </el-table>
    </el-tab-pane>
      <el-tab-pane label="资产">
        <el-table stripe :data="showList3" style="width: 100%"  border fit highlight-current-row :height="contentStyleObj2" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="序号" type="index" align="center" width="55"></el-table-column>
        <el-table-column prop="name"  label="资产名称" align="center" min-width="90" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name"  label="资产类型" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.type == 1">固定资产</span>
            <span v-if="scope.row.type == 2">无形资产</span>
            <span v-if="scope.row.type == 3">费用摊销</span>
          </template>
        </el-table-column>
        <el-table-column prop="name"  label="资产类别" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.assetType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectName" label="累计折旧科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.subjectCode }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fySubjectName"  label="折旧费用科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.fySubjectCode }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" align="center" width="60"> 
          <template #default="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transDate" label="入账日期" align="center" width="120"> 
          <template #default="scope">
            <span>{{ scope.row.transDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalValue" label="原值(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ scope.row.originalValue }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="depreciationPeriods" label="预计使用月份" align="center" width="135"> 
          <template #default="scope">
            <span>{{ scope.row.depreciationPeriods }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="残值率(小数)" align="center" min-width="110"> 
          <template #default="scope">
            <span>{{ scope.row.rmValueRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="期初累计折旧" align="center" min-width="110"> 
          <template #default="scope">
            <span>{{ scope.row.initLjDepreciation }}</span>
          </template>
        </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="存货">
        <el-table stripe :data="showList4" style="width: 100%"  border fit highlight-current-row :height="contentStyleObj2" v-loading="loading" show-summary>
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="序号" type="index" align="center" width="55"></el-table-column>
        <el-table-column prop="name"  label="存货名称" align="center" min-width="200" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="型号" align="center" min-width="100" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.spec }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="单位" align="center" min-width="100" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="科目编码" align="center" min-width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.subjectCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类别" align="center" min-width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="期末数量" align="center" min-width="100" show-overflow-tooltip>  
          <template #default="scope">
            <span>{{ scope.row.endCount }}</span>
          </template>   
        </el-table-column>
        <el-table-column prop="endAmount" label="期末金额" align="center" min-width="100">       
        </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkDialogVisible = false" size="small" type="primary">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <declareDetails ref="declareDetails"></declareDetails>

</template>

<script>
import { companyHisList } from "@/api/company"
import { supplementInventory } from "@/api/build"
import declareDetails from "../batch/components/declareDetails.vue";
import selectCjJzStatus from "./components/selectCjJzStatus.vue"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import { subjectCollectCheck ,assetCollectCheck,empCollectCheck, inventoryCollectCheck } from "@/api/subject"

export default {
  name: "addAccounts",
  components:{
    declareDetails,
    selectCjJzStatus
  },
  data(){
    return {
      activeName:'已建账',
      formLabelWidth: '120px',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:"other-cj-jianzhang",
        period: currentAccountPeriod(),
        name: '',
        taskStatus: [],
        allStatus:[],
        taxNames:[],
        jzStatus:3,
        assetCj:2,
        bcAsset:2,
        empCj:2,
        bcEmp:2
      },
      total:0,
      list:[],
      form: {
        name: '账无忧',
        userName:'',
        password:'',
        orgCode:''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '公司代码', trigger: 'blur' },
        ],
      },
      gsMainIds: undefined,
      cjzt: undefined,
      dialogVisible: false,
      checkDialogVisible:false,
      options: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        },
      ],
      categories: [
        // {text: '利润表', value: 'kj_lrb'}, 
        // {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '财务报表', value: 'gs_cwbb'}, 

        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        // {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '其他收入(工会经费)', value: 'gs_qtsr'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
      contentStyleObj2:{},
      contentStyleObj:{},
      checkComId:null,
      showList2:[],
      showList3:[],
      showList4:[],
      loading:false,
      changeWb:false
    }
  },
  created(){
    this.initBus()
    this.getList()
    this.contentStyleObj= this.$getHeight(250)
    this.contentStyleObj2= this.$getHeight(400)
  },
  methods:{
    initBus(){
      this.$bus.off("addAccountsUpdate")
      this.$bus.on("addAccountsUpdate", (val) => {
        this.getList()
      });
    },
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
        taskName:"other-cj-jianzhang",
        period: currentAccountPeriod(),
        name: '',
        taskStatus: [],
        allStatus:[],
        taxNames:[],
        jzStatus:3,
        assetCj:2,
        bcAsset:2,
        empCj:2,
        bcEmp:2
      }
      this.getList()
    },
    getList(){
      companyHisList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    // 切换未建账已建账
    handleClick(tab){
      if(tab.props.label == '未建账'){
        this.listQuery.jzStatus = 6
        this.getList()
      }else if(tab.props.label == '已建账'){
        this.listQuery.jzStatus = 3
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      this.cjzt = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
      row.map(info=>{
        this.cjzt.push(info.taskStatus)
      })
    },
    //批量补充
    handleCommand(command) {
      let state = true
      this.cjzt.map(item => {
        if(item != 3) {
          state = false
        }
      })
      if(command == 'a'){
        if(state == true) {
          this.$confirm('确定要批量补充存货吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'inventory', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充存货!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }else if(command == 'b'){
        if(state == true) {
          this.$confirm('确定要批量补充资产吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'asset', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充资产!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }else if(command == 'd'){
        if(state == true) {
          this.$confirm('确定要批量补充员工吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'emp', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充员工!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,e.startAccountPeriod,e.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let data = localStorage.getItem("lssj")
      if(data){
        this.form = JSON.parse(data)
      }
      this.dialogVisible = true
    },
    //批量采集确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let cond = {
            // comId: v * 1,
            orgCode: this.form.orgCode,
            userName: this.form.userName,
            password: this.form.password,
            softwareName: this.form.name
          }
          this.dialogVisible = false
          //临时保存账号密码
          localStorage.setItem("lssj",JSON.stringify(this.form))
          sendTask({comIds:this.gsMainIds,taskName: "other-cj-jianzhang",cond}).then(res => {
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        }
      });
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shenbao',
          comIds: [e.comId]
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //查看
    handleCheck(row){
      this.loading = true
      this.checkComId = row.comId
      subjectCollectCheck({comId:row.comId}).then(res => {
        this.loading = false
        if(res.data.msg == 'success'){
          this.showList = res.data.data.list
          this.checkDialogVisible = true
        }
      })
    },
    //查看
    handleDialogClick(tab){
      this.loading = true
      if(tab.props.label == '科目余额表'){
        subjectCollectCheck({comId:this.checkComId}).then(res => {
          this.loading = false
        if(res.data.msg == 'success'){
          this.showList = res.data.data.list
        }
      })
      }else if(tab.props.label == '员工'){
        empCollectCheck({comId:this.checkComId}).then(res => {
          this.loading = false
        if(res.data.msg == 'success'){
          this.showList2 = res.data.data.list
        }
      })
      }else if(tab.props.label == '资产'){
        assetCollectCheck({comId:this.checkComId}).then(res => {
          this.loading = false
        if(res.data.msg == 'success'){
          this.showList3 = res.data.data.list
        }
      })
      }else if(tab.props.label == '存货'){
        inventoryCollectCheck({comId:this.checkComId}).then(res => {
          this.loading = false
        if(res.data.msg == 'success'){
          this.showList4 = res.data.data.list
        }
      })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  //text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
i{
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 3px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
</style>